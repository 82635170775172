import React from 'react';
import VideoPopup from '@components/Fellowship/VideoPopup/index';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDialog } from '../Dialog/index';
import StatsPanel from '../StatsPanel/index';
import CrioButton from '../CrioButton/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_HOME_PROJECTS } from '../../../constants/LeadGenerationConstants/index';
import useSale from '@src/hooks/useSale';
import { cn } from '@/lib/utils';

const HomepageHero = ({ program }) => {
  const { sale } = useSale();

  return (
    <div
      className={cn(
        `mx-auto max-w-[1200px] px-4 ${
          sale ? 'pt-60 xxs:pt-[160px] sm:pt-[160px]' : 'pt-[160px]'
        }`,
      )}
    >
      {<DesktopHero program={program} sale={sale} />}
      {<MobileHero program={program} sale={sale} />}
    </div>
  );
};

const getHeroImage = (sale) => {
  switch (sale) {
    case 'true':
      return (
        <>
          <StaticImage
            loading="eager"
            src="../../../images/v5/Sale-Hero-Image-v2.webp"
            placeholder="none"
            alt="Hero Image"
            width={350}
            imgStyle={{ objectFit: 'contain' }}
            className="w-full md:hidden"
          />
          <StaticImage
            loading="eager"
            src="../../../images/v5/Sale-Hero-Image-v2.webp"
            placeholder="none"
            alt="Hero Image"
            width={450}
            imgStyle={{ objectFit: 'contain' }}
            className="hidden md:block"
          />
        </>
      );
    default:
      return (
        <>
          <StaticImage
            loading="eager"
            src="../../../images/v5/Homepage_Hero_Image.webp"
            placeholder="none"
            alt="Hero Image"
            width={350}
            imgStyle={{ objectFit: 'contain' }}
            className="w-full md:hidden"
          />
          <StaticImage
            loading="eager"
            src="../../../images/v5/Homepage_Hero_Image.webp"
            placeholder="none"
            alt="Hero Image"
            width={450}
            imgStyle={{ objectFit: 'contain' }}
            className="hidden md:block"
          />
        </>
      );
  }
};

const DesktopHero = ({ program, sale }) => {
  return (
    <div className="hidden w-full items-center justify-between lg:flex">
      <div className="flex max-w-[600px] flex-col space-y-6">
        <HeroContent />
        <PlayButton />
        <CTAWrapper program={program} />
        <StatsPanel />
      </div>
      <div>{getHeroImage(sale)}</div>
    </div>
  );
};

const MobileHero = ({ program, sale }) => {
  return (
    <div className="  justify-center lg:hidden ">
      <div className="">
        <div className="flex flex-col space-y-6 text-center">
          <HeroContent />
          <div className="flex w-full justify-center">
            <PlayButton />
          </div>
          <CTAWrapper program={program} />
          <div className="relative w-full">{getHeroImage(sale)}</div>
          <StatsPanel />
        </div>
      </div>
    </div>
  );
};

const HeroContent = () => {
  return (
    <>
      <h1 className="font-manrope text-[32px] font-black capitalize leading-tight sm:text-[44px]">
        Learn like you would <br />
        at India’s Top tech
        <br /> companies.
      </h1>
      <div className=" text-v5-neutral-400">
        <h2 className="mb-6 font-manrope text-xl font-normal">
          Work-experience based learning <br className="block sm:hidden"></br>
          programs to land your dream tech job
        </h2>
        <div className="flex  flex-col space-y-2 font-rubik font-normal sm:space-y-0 md:text-sm">
          <p>
            <strong>Build</strong> professional projects like the top 1% tech
            professionals.
          </p>
          <p>
            <strong>Master</strong> the latest Fullstack/Backend/Automation tech
            with real work-ex.
          </p>
          <p>
            <strong>Crack</strong> your dream role at the best tech companies
          </p>
        </div>
      </div>
    </>
  );
};

const CTAWrapper = ({ program }) => {
  return (
    <>
      <div className="hidden flex-col items-center gap-y-3 space-y-4 sm:flex sm:space-y-0 sm:space-x-4 md:flex-row md:justify-center">
        <div className="">
          <a href="#programs">
            <CrioButton
              variant="secondary"
              className="w-[250px] py-6 sm:w-auto"
            >
              Explore Our Programs
            </CrioButton>
          </a>
        </div>
        <div>
          <LeadGenerationButton
            className="w-[300px]"
            buttonLocation={LEAD_GEN_HOME_PROJECTS}
          />
        </div>
      </div>

      <div className="flex flex-col items-center space-y-4 sm:hidden sm:space-y-0 sm:space-x-4 md:flex-row md:justify-center  ">
        <div className="">
          <a href="#programs">
            <CrioButton
              variant="secondary"
              className="h-[50px] min-w-[270px] py-2"
            >
              Explore Our Programs
            </CrioButton>
          </a>
        </div>
        <div>
          <LeadGenerationButton
            className="w-[300px]"
            buttonLocation={LEAD_GEN_HOME_PROJECTS}
          />
        </div>
      </div>
    </>
  );
};

const PlayButton = () => {
  const [openDialog, closeDialog] = useDialog();

  return (
    <div
      className="group z-[1] flex w-max  cursor-pointer items-center  rounded-full md:relative"
      onClick={() => {
        openDialog({
          children: (
            <VideoPopup
              video={'https://www.youtube.com/embed/hGrRg8aoBMU'}
              closeDialog={closeDialog}
            ></VideoPopup>
          ),
        });
      }}
    >
      <div className="md:mr-2">
        <div
          className={`mr-2 flex h-[28px] w-[28px] items-center justify-center rounded-full bg-v5-green-400 transition-all group-hover:bg-v5-green-100`}
        >
          <FontAwesomeIcon
            icon={['fas', 'play']}
            className={`ml-1 text-xs  text-v5-green-100 group-hover:text-v5-green-400 `}
          />{' '}
        </div>
      </div>
      <p className=" font-rubik text-lg font-bold text-v5-green-400 group-hover:underline">
        Upskill with Crio
      </p>
    </div>
  );
};

export default HomepageHero;
