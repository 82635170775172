import React from 'react';
import SEO from '@components/Common/SEO/index';
import FooterV2 from '@components/FooterV2';
import { graphql } from 'gatsby';
import HomepageHero from '@components/v5/HomepageHero/index';
import CrackedCrioCareersSection from '@components/v5/CrackedCrioCareersSection/index';
import WhyCrioSection from '@components/v5/WhyCrioSection/index';
import CareerServicesSection from '@components/v5/CareerServicesSection/index';
import ProgramsSection from '@components/v5/ProgramsSection/index';
import ChatWithUsSection from '@components/v5/ChatWithUsSection/index';
import CrioComparisionSection from '@components/v5/CrioComparisionSection/index';
import InTheNewsSection from '@components/v5/InTheNewsSection/index';
import './index.css';
import ProjectsSection from '@components/v5/ProjectsSection/index.js';
import CompaniesSection from '@components/v5/CompaniesSection/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection/index';
import PostContentCTALayout from '@components/v5/layouts/PostContentCTALayout/index';
import CrioButton from '@components/v5/CrioButton/index';
import { Link } from 'gatsby';
import { ERoutes } from '@src/constants/routes/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_HOME_PROJECTS,
  LEAD_GEN_DEVELOPERS_CRACKED,
  LEAD_GEN_WHY_CRIO,
  LEAD_GEN_TESTIMONIALS,
  LEAD_GEN_CRIO_COMPARISION,
  LEAD_GEN_COMPANIES,
  LEAD_GEN_CAREER_SERVICES,
  LEAD_GEN_FLOATING_CTA,
} from '../constants/LeadGenerationConstants/index';
import ScrollButtonComponent from '@components/ScrollButtonComponent/index';
import { useWebEngage } from '@src/hooks/useWebEngage';
import NsdcSection from '@components/v5/NsdcSection/index';
import NavigationV2 from '@components/NavigationV2/index';
import useSale from '@src/hooks/useSale';

const HomePage = (props) => {
  const { captureUserClickEvent } = useWebEngage();
  const { sale } = useSale();

  const onPlacementButtonClick = () => {
    captureUserClickEvent('Get our Placement Stats', {
      placementLocation: 'HOME_PAGE',
    });
  };

  const program = props.data.allStrapiPrograms.nodes.find(
    (ele) => ele.PrimaryProgramID == 'FREE_TRIAL',
  );

  const getProjectsSectionPreContent = (sale) => {
    switch (sale) {
      case 'true':
        return <></>;

      default:
        return <></>;
    }
  };

  return (
    <div>
      <SEO canonicalUrl={'https://crio.do'}>
        <script type="application/ld+json">{`
     {
      "@type": "Organization",
      "name": "Crio.Do",
      "legalName": "Crio.Do",
      "description": "Build professional work-like projects and accelerate your full stack, backend, or SDET career with a job-ready portfolio. Crio is an approved training partner of NSDC.",
      "url": "https://www.crio.do/",
      "logo": "",
      "foundingDate": "2018",
      "founders": [
        {
          "@type": "Person",
          "givenName": "Rathinamurthy",
          "familyName": "R",
          "jobTitle": {
            "@type": "DefinedTerm",
            "name": "Co-Founder &amp; CEO",
            "sameAs": [
              "https://www.linkedin.com/in/rathina/",
              "https://twitter.com/rathinamurthy"
            ]
          }
        },
        {
          "@type": "Person",
          "givenName": "Sridher",
          "familyName": "Jeyachandran",
          "jobTitle": {
            "@type": "DefinedTerm",
            "name": "Co-Founder &amp; CEO",
            "sameAs": [
              "https://www.linkedin.com/in/sridher-jeyachandran/",
    
              "https://yourstory.com/people/rathinamurthy-r?origin=awards",
              "https://www.crunchbase.com/person/rathinamurthy-r",
              "https://yourstory.com/people/sridher-jeyachandran?origin=awards",
              "https://www.crunchbase.com/person/sridher-jeyachandran"
            ]
          }
        }
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "175 176, Bannerghatta Main Rd, Dollars Colony, Phase 4, J. P. Nagar",
        "addressLocality": "Bengaluru",
        "addressRegion": "Karnataka",
        "postalCode": "560076",
        "addressCountry": "India"
      },
      "sameAs": [
        "https://twitter.com/crio_do",
        "https://www.linkedin.com/company/criodo",
        "https://www.instagram.com/crio.do",
        "https://www.facebook.com/criodo",
        "https://www.youtube.com/channel/UCE1_bZxswCCARhhVeSQLuew",
        "https://www.crunchbase.com/organization/crio-do",
        "https://yourstory.com/companies/criodo/amp",
        "https://angel.co/company/crio-do/"
  
      ]
    } 
  `}</script>
      </SEO>
      <ScrollButtonComponent
        component={
          <LeadGenerationButton
            type="Apply & Start Free"
            buttonLocation={LEAD_GEN_FLOATING_CTA}
          />
        }
        startAt={600}
      ></ScrollButtonComponent>
      <NavigationV2 program={program.PrimaryProgramID} />
      <HomepageHero program={program} sale={sale} />
      <CrackedCrioCareersSection
        postContent={
          <PostContentCTALayout>
            <div className="flex flex-wrap justify-center gap-x-4 gap-y-4">
              <Link to={ERoutes.PLACEMENTS}>
                <CrioButton
                  onClick={onPlacementButtonClick}
                  variant="secondary"
                  className="h-[56px] w-[300px] py-6 sm:w-auto"
                >
                  Get our Placement Stats
                </CrioButton>
              </Link>
              <LeadGenerationButton
                className="w-[300px]"
                buttonLocation={LEAD_GEN_DEVELOPERS_CRACKED}
              />
            </div>
          </PostContentCTALayout>
        }
      ></CrackedCrioCareersSection>
      <ProjectsSection
        postContent={
          <PostContentCTALayout>
            <div>
              <LeadGenerationButton
                className="w-[300px]"
                buttonLocation={LEAD_GEN_HOME_PROJECTS}
              />
            </div>
          </PostContentCTALayout>
        }
        preContent={getProjectsSectionPreContent(sale)}
        program={program.PrimaryProgramID}
      ></ProjectsSection>
      <WhyCrioSection
        postContent={
          <PostContentCTALayout>
            <div>
              <LeadGenerationButton
                className="w-[300px]"
                buttonLocation={LEAD_GEN_WHY_CRIO}
              />
            </div>
          </PostContentCTALayout>
        }
      ></WhyCrioSection>
      <ProgramsSection sale={sale}></ProgramsSection>
      <CompaniesSection
        postContent={
          <PostContentCTALayout>
            <div>
              <LeadGenerationButton
                className="w-[300px]"
                buttonLocation={LEAD_GEN_COMPANIES}
              />
            </div>
          </PostContentCTALayout>
        }
      ></CompaniesSection>
      <CareerServicesSection
        postContent={
          <PostContentCTALayout>
            <div>
              <LeadGenerationButton
                className="w-[300px]"
                buttonLocation={LEAD_GEN_CAREER_SERVICES}
              />
            </div>
          </PostContentCTALayout>
        }
      ></CareerServicesSection>
      <VideoTestimonialSection
        postContent={
          <PostContentCTALayout>
            <div>
              <LeadGenerationButton
                className="w-[300px]"
                buttonLocation={LEAD_GEN_TESTIMONIALS}
              />
            </div>
          </PostContentCTALayout>
        }
      ></VideoTestimonialSection>

      <NsdcSection extendedClassName="mt-7" />

      <ChatWithUsSection></ChatWithUsSection>
      <CrioComparisionSection
        postContent={
          <PostContentCTALayout>
            <div>
              <LeadGenerationButton
                className="w-[300px]"
                buttonLocation={LEAD_GEN_CRIO_COMPARISION}
              />
            </div>
          </PostContentCTALayout>
        }
      ></CrioComparisionSection>
      <InTheNewsSection></InTheNewsSection>
      <FooterV2 />
    </div>
  );
};

export const pageQuery = graphql`
  query {
    ...programImagesFragment

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }
  }
`;

export default HomePage;
